import React from 'react';

const ProductTable = props => (
  <section className="mt-16">
    <table className="w-full my-8">
      <thead className="bg-teal-800 text-left">
        <tr>
          <th className="p-3 text-white">
            {props.isEnglish ? 'Name' : 'Nombre'}
          </th>
          <th className="p-3 text-white">
            {props.isEnglish ? 'Size' : 'Tamaño'}
          </th>
          <th className="p-3 text-white">
            {props.isEnglish ? 'Thickness' : 'Grosor'}
          </th>
        </tr>
      </thead>
      {props.children}
    </table>
  </section>
);

export default ProductTable;
