import React from 'react';
import { Link } from 'gatsby';

const ProductHero = props => {
  return (
    <section className="flex flex-col sm:flex-row">
      <div className="w-full sm:w-1/2 md:pr-4">
        <h1 className="text-teal-700 text-4xl my-8 leading-tight font-black">
          {props.title}
        </h1>
        <h3 className="font-bold text-blue-900 text-lg">
          {props.isEnglish ? 'Description' : 'Descripción'}
        </h3>
        <p className="mb-6">
          {props.description}
          {/* <Link to={'/sustentabilidad'}>Certificado 100% con FSC.</Link> */}
        </p>

        <h3 className="font-bold text-blue-900 text-lg">
          {props.isEnglish ? 'Uses' : 'Usos'}
        </h3>
        <p className="mb-6">{props.uses}</p>
        <Link
          to={props.isEnglish ? '/en/contact/' : '/contacto/'}
          className="font-bold text-white bg-teal-600 py-4 px-8 inline-block mt-4 shadow-lg"
        >
          {props.isEnglish ? 'Get a Quote' : 'Solicita un presupuesto'}
        </Link>
      </div>
      <div className="w-full sm:w-1/2">{props.children}</div>
    </section>
  );
};

export default ProductHero;
